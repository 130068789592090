import React, { Component } from 'react';
import TechAppDialog from './TechAppDialog';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import {formatHTML} from '../data/utils';
import * as MdIcons from "react-icons/md";
import OdooDatabase from '../data/odoo';
import Loading from './LoadingComponent';
import TextModalEdit from './editors/TextModalEditComponent';
import Notification from './NotificationComponent';
import SelectEditComponent from './editors/SelectEditComponent';
import Button from '@material-ui/core/Button';
import TextEditDialog from './editors/TextEditDialog';
import AttachmentsComponent from './wosubcomponents/AttachmentsComponent'

type AssetModalProperties = {
    open: boolean;
    onClose(): void;
    onError(msg: string): void;
    asset: any;
}

export default class AssetModalDialog extends Component<AssetModalProperties, any>{
    constructor(props: any){
        super(props);
        this.state = {
            loadingSpecifications: false,
            specifications: [],
            specificationsError: false,
            specificationsLoaded: false,
            updating: false,
            showMessage: false,
            message: '',
            addCommentDialogOpen: false,
            asset_special_instructions: this.props.asset.special_instructions,
        }
        this.onAccordionExpanded = this.onAccordionExpanded.bind(this);
        this.updateSpecificationExtraValue = this.updateSpecificationExtraValue.bind(this);
        this.updateSpecificationValue = this.updateSpecificationValue.bind(this);
        this.handleCloseSnackBar = this.handleCloseSnackBar.bind(this);
        this.onAddCommentClick = this.onAddCommentClick.bind(this);
        this.onCloseAddComment = this.onCloseAddComment.bind(this);
        this.onSaveComment = this.onSaveComment.bind(this);
    }

    onAccordionExpanded(event: Object, expanded: boolean){
        if(expanded){
            if(!this.state.specificationsLoaded && !this.state.loadingSpecifications){
                this.loadSpecifications();
            }
        }
    }

    onAddCommentClick(){
        this.setState({addCommentDialogOpen: true});
    }

    onCloseAddComment(){
        this.setState({addCommentDialogOpen: false});
    }

    async onSaveComment(value: string){
        this.setState({addCommentDialogOpen: false});
        this.setState({updating: true});
        try{
            let odoo = OdooDatabase.getInstance();
            const result = await odoo.execute_kw('fieldservice.asset',
                'add_special_instruction',
                [[this.props.asset.id, value]]);
            this.setState({updating: false});
            this.props.asset.special_instructions = result.special_instructions;
            this.setState({
                asset_special_instructions: result.special_instructions
            })
        }
        catch(err: any){
            console.log('Error', err);
            this.setState({updating: false});
            this.setState({showMessage: true, message: "Error adding comment"});
        }
    }

    async loadSpecifications(){
        const asset_id = this.props.asset.id;
        this.setState({loadingSpecifications: true, specificationsError: false});
        try{
            let odoo = OdooDatabase.getInstance();
            const specifications = await odoo.searchRead('fieldservice.asset.specs.rel',
                ['spec_asset_rel_id.id', '=', asset_id],
                ['name', 'specs_rel_id', 'spec_item_id', 'specvalue', 'asequence', 'isspectattr','isspecgeneric', 'is_missing']
            );
            let spec_types = [];
            for(let i = 0; i < specifications.length; i++)
                if(specifications[i].specs_rel_id && spec_types.indexOf(specifications[i].specs_rel_id[0]) < 0)
                    spec_types.push(specifications[i].specs_rel_id[0]);
            const spec_items = await odoo.searchRead('fieldservice.specs.items',
                ['aspec_id', 'in', spec_types],
                ['name', 'aspec_id']);
            for(let i = 0; i < specifications.length; i++){
                let specification = specifications[i];
                let items = [];
                if(specification.specs_rel_id){
                    let spec_type = specification.specs_rel_id[0];
                    for(let j = 0; j < spec_items.length; j++){
                        let spec_item = spec_items[j];
                        if(spec_item.aspec_id[0] === spec_type)
                            items.push({value: spec_item.id, label: spec_item.name});
                    }
                }
                specification.items = items;
            }
            this.setState({specifications: specifications,
                loadingSpecifications: false,
                specificationsLoaded: true});
        }
        catch(err: any){
            console.log('Error', err);
            this.setState({loadingSpecifications: false, specificationsError: true});
        }
    }

    async updateSpecificationValue(recordId: number, value: any): Promise<boolean>{
        console.log('Updating specification', recordId, value);
        let result = false;
        try{
            let odoo = OdooDatabase.getInstance();
            result = await odoo.update('fieldservice.asset.specs.rel', recordId, {
                spec_item_id: value
            });
        }
        catch(err: any){
            console.log('Error', err);
        }
        if(!result){
            this.setState({showMessage: true, message: "Error updating specification value"});
        }
        return result;
    }

    async updateSpecificationExtraValue(recordId: number, value: string): Promise<boolean>{
        console.log('Updating specification', recordId, value);
        let result = false;
        try{
            let odoo = OdooDatabase.getInstance();
            result = await odoo.update('fieldservice.asset.specs.rel', recordId, {
                specvalue: value
            });
        }
        catch(err: any){
            console.log('Error', err);
        }
        if(!result){
            this.setState({showMessage: true, message: "Error updating specification value"});
        }
        return result;
    }

    handleCloseSnackBar(){
        this.setState({showMessage: false, message: ''});
    }

    render(){
        const asset = this.props.asset;
        return (
            <>
            <Notification msg={this.state.message} onClose={this.handleCloseSnackBar} error={true}/>
            <TextEditDialog
                open={this.state.addCommentDialogOpen}
                title="Add Comment"
                initialValue={''}
                onOK={this.onSaveComment}
                onClose={this.onCloseAddComment}
                clearOnOK={true}
            />
            <TechAppDialog
                open={this.props.open}
                title={this.props.asset.name}
                onClose={this.props.onClose}
                closeOnClickOutside={true}
            >
                <>
                <Accordion defaultExpanded>
                    <AccordionSummary
                        expandIcon={<MdIcons.MdExpandMore/>}
                        aria-controls="asset_modal_header"
                        id="asset_modal_header"
                        >
                        <Typography>Asset</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <table className="data_table full_width">
                            <tbody>
                                <tr>
                                    <td>
                                        ID
                                    </td>
                                    <td>
                                        {asset.name}
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Type
                                    </td>
                                    <td>
                                        {asset.asset_type === 'E' ? 'Equipment' : 'Site'}
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Category
                                    </td>
                                    <td>
                                        {asset.acategory_id && asset.acategory_id.length >= 2 ? asset.acategory_id[1] : ''}
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Model
                                    </td>
                                    <td>
                                        {asset.amodel}
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        SN
                                    </td>
                                    <td>
                                        {asset.asnumber}
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Class
                                    </td>
                                    <td>
                                        {asset.asset_class_id && asset.asset_class_id.length >= 2 ? asset.asset_class_id[1] : ''}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </AccordionDetails>
                </Accordion>
                <Accordion onChange={this.onAccordionExpanded}>
                    <AccordionSummary
                        expandIcon={<MdIcons.MdExpandMore/>}
                        aria-controls="asset_specifications_header"
                        id="asset_specifications_header"
                        >
                        <Typography>Specifications</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        {
                        this.state.loadingSpecifications ?
                        (<Loading loading={true} fullWidth={false}/>)
                        :
                        this.state.specificationsError ?
                        (<span>Error loading specifications</span>):
                        (<table className="data_table">
                            <thead>
                                <tr>
                                    <td>Spec</td>
                                    <td>Value</td>
                                    <td>Extra Value</td>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    this.state.specifications.map((specification: any) => {
                                        const readonly = specification.isspecgeneric || specification.isspectattr === 'Asset Class';
                                        return (<tr key={`table_row_${specification.id}`}>
                                            <td>{specification.specs_rel_id && specification.specs_rel_id.length >= 2 ? specification.specs_rel_id[1]: ''}</td>
                                            <td>
                                                {
                                                specification.isspecgeneric ? (<></>):
                                                (<SelectEditComponent
                                                    key={`spec_value_${specification.id}`}
                                                    recordId={specification.id}
                                                    editable={!readonly}
                                                    value={specification.spec_item_id[0]}
                                                    selectItems={specification.items}
                                                    recordUpdate={this.updateSpecificationValue}
                                                    workingFullScreen={false}
                                                />)
                                                }
                                            </td>
                                            <td className="asset_edit_value"><TextModalEdit
                                                key={`spec_extravalue_${specification.id}`}
                                                dialogTitle={'Edit Specification Value'}
                                                recordId={specification.id}
                                                initialValue={specification.specvalue ? specification.specvalue : ''}
                                                recordUpdate={this.updateSpecificationExtraValue}
                                            /></td>
                                        </tr>);
                                    })
                                }
                            </tbody>
                        </table>)
                        }
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<MdIcons.MdExpandMore/>}
                        aria-controls="asset_special_instructions_header"
                        id="asset_special_instructions_header"
                        >
                        <Typography>Special Instructions</Typography>
                    </AccordionSummary>
                    <AccordionDetails className="display_flex_column">
                    <div dangerouslySetInnerHTML={{ __html: formatHTML(this.state.asset_special_instructions) }} />
                    {
                        this.state.updating ?
                        (<Loading loading={this.state.updating} fullWidth={false}/>) :
                        (<Button variant="contained" color="primary" onClick={this.onAddCommentClick}>ADD COMMENT</Button>)

                    }
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<MdIcons.MdExpandMore/>}
                        aria-controls="asset_special_instructions_header"
                        id="asset_special_instructions_header"
                        >
                        <Typography>Attachments</Typography>
                    </AccordionSummary>
                    <AccordionDetails className="display_flex_column">
                        <AttachmentsComponent
                            source_model="fieldservice.asset"
                            record_id={this.props.asset.id}
                            onError={this.props.onError}
                            saveModel="fieldservice.asset"
                            saveMethod="add_attachment"
                            buttonsBoxClassName="full_width_buttons"
                        />

                    </AccordionDetails>

                </Accordion>
                </>
            </TechAppDialog>
            </>
        );
    }
}